module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"family-solutions-website","short_name":"Family Solutions","start_url":"/","icon":"src/assets/images/faviconwhite.png","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"afe0df8b09d052c57848a3572be4a1d1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fresnel/gatsby-browser.js'),
      options: {"plugins":[],"breakpoints":{"mobile":0,"tablet":600,"desktop":1280,"desktop_large":1920}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"custom":[{"name":["Cerco"],"file":"/fonts/styles.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
